<template>
  <div class="milestones">
    <v-app-bar flat color="transparent" class="main-appbar mx-4">
      <app-bar-nav-icon />

      <v-toolbar-title class="headline font-weight-bold pa-0">
        Milestones
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <v-row>
        <v-col cols="12">
          <v-row>
            <template v-for="(milestone, index) in milestones">
              <v-col :key="milestone.id" class="milestone" cols="12" md="4">
                <Milestone class="mb-6" :milestone="milestone" :count="index" />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Milestone from './components/Milestone'

import { mapState } from 'vuex'

export default {
  components: {
    AppBarNavIcon,
    Milestone
  },

  async created() {
    await this.$store.dispatch('milestone/getMilestones')
  },

  computed: {
    ...mapState({
      milestones: state => state.milestone.list
    })
  }
}
</script>
