<template>
  <v-card outlined>
    <v-card-title class="align-start pb-0">
      <div>
        <span class="text-h5"> {{ plan.name }} </span>
        <p class="caption">{{ plan.description }}</p>
      </div>
      <v-spacer />
      <span class="font-weight-bold"> GBP £{{ plan.price / 100 }} </span>
    </v-card-title>
    <v-card-actions class="px-4 pb-4">
      <v-btn
        class="font-weight-bold"
        color="primary"
        @click="startPlanUpdate(plan)"
        block
      >
        Update Plan
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="updateDialog" max-width="500" persistent>
      <v-card>
        <v-card-title> Update Plan </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                label="Plan Name"
                :error-messages="form.$getError('name')"
                :hide-details="!form.$hasError('name')"
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="form.description"
                label="Plan Description"
                rows="3"
                :error-messages="form.$getError('description')"
                :hide-details="!form.$hasError('description')"
                auto-grow
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.product_id"
                label="Stripe product id"
                :error-messages="form.$getError('product_id')"
                :hide-details="!form.$hasError('product_id')"
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.product_price_id"
                label="Stripe product price id"
                :error-messages="form.$getError('product_price_id')"
                :hide-details="!form.$hasError('product_price_id')"
                clearable
                outlined
                flat
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-row dense>
            <v-col>
              <v-btn
                color="error"
                :disabled="form.$busy"
                @click="cancelUpdate()"
                block
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                :loading="form.$busy"
                :disabled="!form.$busy && !form.$isDirty()"
                @click="continuePlanUpdate()"
                block
              >
                Update Plan
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Form from '@/utils/form'

import { mapMutations, mapActions } from 'vuex'

export default {
  props: {
    plan: {
      type: Object,
      require: true
    }
  },

  data() {
    return {
      updateDialog: false,
      updatingPlan: false,

      form: new Form({})
    }
  },

  mounted() {
    this.setForm()
  },

  methods: {
    ...mapMutations({
      updateListItem: 'plans/updateListItem',
      setDetails: 'plans/setDetails'
    }),

    ...mapActions({
      updatePlan: 'plans/updatePlan',
      showSnackbar: 'showSnackbar'
    }),

    setForm() {
      this.form = new Form({ ...this.plan })
    },

    cancelUpdate() {
      this.updateDialog = false
      this.setForm()
    },

    async startPlanUpdate(plan) {
      await this.setDetails(plan)

      this.updateDialog = true
    },

    async continuePlanUpdate() {
      this.form.$clearErrors()

      this.form.$busy = true

      try {
        const {
          data: { data }
        } = await this.updatePlan(this.form)

        this.form.$refresh(data)

        setTimeout(() => {
          this.updateDialog = false
          this.form.$busy = false

          this.updateListItem(data)
          this.showSnackbar({ message: `${this.form.name} Updated!` })
        }, 800)
      } catch (error) {
        if (error?.response?.status === 422) {
          this.form.$setErrors(error.response.data.errors)
        }

        this.showSnackbar({
          color: 'error',
          message: error.response.data.message
        })

        this.form.$busy = false
      }
    }
  }
}
</script>
