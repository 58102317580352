<template>
  <div class="plans">
    <v-app-bar flat color="transparent" class="main-appbar mx-4">
      <app-bar-nav-icon />

      <v-toolbar-title class="headline font-weight-bold pa-0">
        Plans
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col v-for="plan in plans" :key="plan.id" cols="12" class="mb-6">
              <Plan :plan="plan" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Plan from './components/Plan'

import { mapState } from 'vuex'

export default {
  components: {
    AppBarNavIcon,
    Plan
  },

  async created() {
    await this.$store.dispatch('plans/getPlans')
  },

  computed: {
    ...mapState({
      plans: state => state.plans.list
    })
  }
}
</script>
